// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
require("@rails/ujs").start()
require("turbolinks").start()

// import Turbolinks from 'turbolinks';
// Turbolinks.start();

require("@rails/activestorage").start()
require("channels")
import 'popper.js'
// import "bootstrap"
// import 'bootstrap-icons/font/bootstrap-icons.css'
import { Tooltip, Popover, Multiselect, Carousel, Tabs } from 'bootstrap';

// import Alert from 'bootstrap/js/dist/alert';
import I18n from 'i18n-js'
window.I18n = I18n
// import "@fortawesome/fontawesome-free/css/all"

// import '../src/stylesheets/application.scss'
const images = require.context('../images', true);

var jQuery = require('jquery')

// include jQuery in global and window scope (so you can access it globally)
// in your web browser, when you type $('.div'), it is actually refering to global.$('.div')
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

// STIMULUS

// export default class extends Controller {
// }
// import { uppy } from '@uppy/core'


import { Application } from "stimulus"
import { Controller } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

require("trix");
require("@rails/actiontext");

const application = Application.start()
const context = require.context("controllers", true, /\.js$/)
application.load(definitionsFromContext(context))

// import Dashboard from '@uppy/dashboard'
// import GoogleDrive from '@uppy/google-drive'
// import Instagram from '@uppy/instagram'
// import Webcam from '@uppy/webcam'
// import Tus from '@uppy/tus'

// import moment from 'moment'
import moment from 'moment-timezone';
// import 'moment-range';
window.moment = moment
moment().tz("Europe/Riga").format();

require("flatpickr");
require("flatpickr/dist/l10n/ru.js");
require("flatpickr/dist/l10n/lv.js");
import flatpickr from "flatpickr"
window.flatpickr = flatpickr;

document.addEventListener("turbolinks:load", () => {
  flatpickr("[class='flatpickr']", {})
})

// import tempusDominus from "tempus-dominus"
// window.tempusDominus = tempusDominus;
// // require("@eonasdan/tempus-dominus");
// tempusDominus.extend(tempusDominus.plugins.moment_parse, 'DD.MM.yyyy hh:mm a');
// const datetimepicker1 = new tempusDominus.TempusDominus(document.getElementById('datetimepicker1'));
// // window.flatpickr = flatpickr;






require("jquery-countdown");
require('jquery-ui/themes/base/all.css');
require("jquery-ui/ui/widget");
require("jquery-ui/ui/widgets/sortable");
require("jquery-ui/ui/widgets/draggable");
require("jquery-ui/ui/widgets/droppable");
require("jquery-ui/ui/widgets/resizable.js");
// require("jquery-ui/ui/widgets/button.js");
// require("jquery-ui/ui/widgets/spinner.js");
// require("jquery.ns-autogrow");

import toastr from 'toastr'
window.toastr = toastr


// import "toaster-js/default.scss";

import Swiper, { Navigation, Autoplay } from 'swiper';
Swiper.use([Navigation, Autoplay]);
// import Noise from 'noise';

// import counterUp from 'counterup2';
// require('waypoints/lib/jquery.waypoints');

// include jQuery in global and window scope (so you can access it globally)
// in your web browser, when you type $('.div'), it is actually refering to global.$('.div')

import Masonry from 'masonry-layout'
import imagesLoaded from 'imagesloaded';


require("../src/scripts/map");
// import '../src/stylesheets/application.scss'

// DASHCORE theme
// require("../packs/template/dashcore/js/lib.min.js");
// require("../packs/template/dashcore/js/demo.js");
// require("../packs/template/dashcore/js/card.js");
// require("../packs/template/dashcore/js/counterup2.js");
// require("../packs/template/dashcore/js/noise.js");
// require("../packs/template/dashcore/js/noframework.waypoints.js");
// require("../packs/template/dashcore/js/odometer.js");
// require("../packs/template/dashcore/js/prism.js");
// require("../packs/template/dashcore/js/simplebar.js");
// require("../packs/template/dashcore/js/swiper-bundle.js");
require("../packs/template/dashcore/js/jquery.easing.js");
// require("../packs/template/dashcore/js/jquery.validate.js");
// require("../packs/template/dashcore/js/jquery.smartWizard.js");
// require("../packs/template/dashcore/js/feather.js");
// require("../packs/template/dashcore/js/aos.js");

// require("../packs/template/dashcore/js/jquery.magnific-popup.js");
// require("../packs/template/dashcore/js/cookieconsent.js");
// require("../packs/template/dashcore/js/jquery.animatebar.js");
require("../packs/template/dashcore/js/common.js");
// require("../packs/template/dashcore/js/forms.js");
// require("../packs/template/dashcore/js/stripe-bubbles.js");
require("../packs/template/dashcore/js/stripe-menu.js");
// require("../packs/template/dashcore/js/credit-card.js");
// require("../packs/template/dashcore/js/pricing.js");
// require("../packs/template/dashcore/js/shop.js");
// require("../packs/template/dashcore/js/svg.js");
// require("../packs/template/dashcore/js/site.js");
// require("../packs/template/dashcore/js/wizards.js");
// require("../packs/template/dashcore/js/dashcore.min.js");
// require("../packs/template/dashcore/js/demo.js");
require("../packs/plugins/featherlight/featherlight.js");
require("../packs/plugins/featherlight/featherlight.gallery.js");
require("../packs/plugins/jquery.multi-select.min.js");
require("../packs/plugins/cocoon.js");
require("../packs/plugins/social-share-button.js");
// require("../packs/plugins/smart-guides/smart-guides.js");

// require("../packs/plugins/smart-guides/jquery.draggable.smartguides.js");
// require("../packs/plugins/smart-guides/jquery.resizable.smartguides.js");



// require("../packs/plugins/jquery.query-object.js");

require("../packs/games/memory_game.js");

require("../packs/builder.js");
require("../packs/shop.js");

// import * as htmlToImage from 'html-to-image';
// import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';
// import domtoimage from 'dom-to-image';
// import domtoimage from "dom-to-image-more";
// const htmlToImage = require('../packs/plugins/html-to-image.js');

import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg, toSvg } from 'html-to-image';
import { Canvg } from 'canvg';
import SVGtoPDF from 'svg-to-pdfkit';
import { jsPDF } from "jspdf";

import { saveAsPng, saveAsJpeg } from 'save-html-as-image';

import Lightbox from 'bs5-lightbox';


const AOS = require('../packs/template/dashcore/js/aos.js');
const Typed = require("../packs/template/dashcore/js/typed.js");

// CALENDAR
// window.Calendar = require("@fullcalendar/core").Calendar;
// window.dayGridPlugin = require("@fullcalendar/daygrid").default;
// window.listPlugin = require("@fullcalendar/list").default;
// window.calLocaleLv = require('@fullcalendar/core/locales/lv').default;


// require("@nathanvda/cocoon");
// import 'cropperjs'
// import { Cropper } from "cropperjs"
// const Cropper = require('cropperjs');
// require("../packs/plugins/cropperjs.js");


// require("cocoen/cocoen.js");

// const cocoen = require("../packs/plugins/cocoen.js");


function initInlineMaps() {
  if ($(".inline-map").length) {
    $(".inline-map").each(function(i, el) {
      let icon = L.icon({
        iconUrl: "https://digi.wedding/pub_imgs/digi_pin.png",
        iconSize: [28, 41], // size of the icon
        popupAnchor: [13,-10],
        iconAnchor: [10, 35]
      })

      let id = $(el).attr("id");
      let coordinates = $(el).data("coordinates");
      let map = L.map(id).setView(coordinates, 15);

      L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
          attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      }).addTo(map);

      L.marker(coordinates, { icon: icon }).addTo(map)

    });
  }
}

function initLightbox() {
  $('.featherligh-gallery').featherlightGallery({
      previousIcon: '&#9664;',
      nextIcon: '&#9654;',
      galleryFadeIn: 100,
      galleryFadeOut: 300
  });

  $.featherlightGallery.prototype.afterContent = function() {
    var caption = this.$currentTarget.find('.album-image-item').attr('alt');
    this.$instance.find('.caption').remove();
    $('<div class="caption">').text(caption).appendTo(this.$instance.find('.featherlight-content'));
  };
}

function initMasonry() {

// var elem = document.querySelector('.grid');
// var msnry = new Masonry( elem, {
//   // options
//   itemSelector: '.grid-item',
//   columnWidth: 200
// });

//   // msnry.once('layoutComplete', () => {
//   //   grid.classList.add('load')
//   // })

//   // msnry.layout()

//   var grid = document.querySelector('.masonry-grid');
//   var msnry = new Masonry( grid, {
//     // options...
//     itemSelector: '.album-image-item',
//     columnWidth: 200
//   });

  // init with selector

  // let elem = document.querySelector('.masonry-grid');
  // let msnry = new Masonry( elem, {
  //   // options
  //   itemSelector: '.grid-item',
  //   columnWidth: 200
  // });

  // const $container = $('.masonry-grid');6
  if ($(".masonry-grid").length) {
    imagesLoaded( 'body', function() {
      // alert('āsd')
      // console.log('imgs');
      var msnry = new Masonry( '.masonry-grid',
        { itemSelector: '.vision-item',
          columnWidth: '.vision-item',
          // columnWidth: 200,
          gutter: 20,
          // fitWidth: true,
          percentPosition: true,
          // horizontalOrder: true,
          initLayout: true
        });
    })
  }
}





// import Beerslider from 'beerslider'

// import 'beerslider'
// import "@fortawesome/fontawesome-free/css/all"
// import { Beerslider } from "beerslider"
// import toastr from 'toastr'
// window.Beerslider = require("beerslider");
// const Beerslider = require('../packs/plugins/beerslider.js');
// window.Beerslider = Beerslider

const BeerSlider = require('../packs/plugins/beerslider/beerslider.js').BeerSlider;



$( document ).ready(function() {
  document.querySelectorAll('.lightbox-element').forEach(el => el.addEventListener('click', Lightbox.initialize));



  initLocation();
  // hideFlashMessage();
  // verificationForm();
  // stepForm();
  // copyUrl();
  // initSortable();
  // initInlineMaps();
  // initLocation();
  // initLightbox();
  // initSwiper();
  // window.initSortable = function() { initSortable() }
  // window.initToggleChecks = function() { initToggleChecks() }
  // window.initDatepicker = function() { initDatepicker() }
  // window.initCalendar = function() { initCalendar() }
  // window.flatpickr = function(e, options) {
  //   $(e).flatpickr(options);
  // }
  // window.initTables = function() { initTables() }
  // // initDatatable();
  // initToggleChecks();
  // initCalendar();
  // initTables();
  // initVendorTemplate();

  // $('.multiselect').multiSelect({
  //   noneText: '-- filtrēt --',
  //   search: true,
  //   columns: 4
  // });
  // initGuestSearch();
  // initTaskSearch();
  // initMasonry();


  // AOS.init({
  //   offset: 100,
  //   duration: 1500,
  //   disable: "mobile"
  // });

  // if ($(".typed").length) {
  //   $(".typed").each(function(i, el) {
  //     var strings = $(el).data("strings");


  //     var typed = new Typed(".typed", {
  //       strings: strings,
  //       typeSpeed: 150,
  //       backDelay: 500,
  //       backSpeed: 50,
  //       loop: true
  //     });
  //   });
  // }

  // // flatpickr.localize('Latvian');

  // let format = 'DD.MM.YYYY HH:mm';
  // flatpickr(".flatpickr", {
  //   enableTime: true,
  //   localize: 'Latvian',
  //   dateFormat: format,
  //   time_24hr: true,
  //   defaultHour: 12,
  //   allowInput: true,
  //   parseDate: (datestr, format) => {
  //     return moment(datestr, format, true).toDate();
  //   },
  //   formatDate: (date, format, locale) => {
  //     // locale can also be used
  //     return moment(date).format(format);
  //   }
  // });

  // let date_format = 'YYYY-MM-DD';
  // flatpickr(".datepicker", {
  //   localize: 'Latvian',
  //   dateFormat: date_format,
  //   allowInput: true,
  //   parseDate: (datestr, format) => {
  //     return moment(datestr, date_format, true).toDate();
  //   },
  //   formatDate: (date, format, locale) => {
  //     // locale can also be used
  //     return moment(date).format(date_format);
  //   }
  // });

  // $.fn.BeerSlider = function ( options ) {
  //   options = options || {};
  //   return this.each(function() {
  //     new BeerSlider(this, options);
  //   });
  // };
  // $('.beer-slider').BeerSlider({start: 40});


});


$(document).on('page:load', function() {
  console.log('1');
})
// $(document).on('turbolinks:load', readyFn);

$(document).on('turbolinks:load', function() {
  initKlix();
  initTopMenu();
  hideFlashMessage();
  verificationForm();
  stepForm();
  copyUrl();
  initSortable();
  initInlineMaps();
  // initLocation();
  initLightbox();
  initSwiper();
  initSlideshow();

  initVendorSwiper();

  window.initSortable = function() { initSortable() }
  window.initToggleChecks = function() { initToggleChecks() }
  window.initDatepicker = function() { initDatepicker() }
  window.initCalendar = function() { initCalendar() }
  window.flatpickr = function(e, options) {
    $(e).flatpickr(options);
  }
  window.reloadPage = function() { reloadPage() }
  window.digi_notifiction = function(id, html) { digi_notifiction(id, html) }
  window.start_loading = function(id) { start_loading(id) }
  window.stop_loading = function(id) { stop_loading(id) }
  

  window.initVendorIndexMap = function() { initVendorIndexMap() }


  window.initTables = function() { initTables() }
  window.initCards = function() { initCards() }
  window.initVisionBoard = function() { initVisionBoard() }


  window.initCocoonEvents = function() { initCocoonEvents() }

  window.call_modal = function(target, data, wrapper_id) { call_modal(target, data, wrapper_id) }

  window.initLightbox = function() { initLightbox() }

  // initDatatable();
  initToggleChecks();
  // toggleInvitationBulkActions();
  initCalendar();
  initTables();
  initCards();
  initVisionBoard();
  initVendorTemplate();

  initCountdown();

  $('.multiselect').multiSelect({
    noneText: $('#guest-index-filter').data('none_filter_text'),
    search: true,
    columns: 4
  });
  initGuestSearch();
  initTaskSearch();
  initMasonry();
  initVendorMap();
  initVendorIndexMap();
  initVendorProfileMap();

  // initCards();
  transformScaleBlocks();

  AOS.init({
    offset: 100,
    duration: 1500,
    disable: "mobile"
  });

  if ($(".typed").length) {
    $(".typed").each(function(i, el) {
      var strings = $(el).data("strings");


      var typed = new Typed(".typed", {
        strings: strings,
        typeSpeed: 150,
        backDelay: 500,
        backSpeed: 50,
        loop: true
      });
    });
  }

  // flatpickr.localize('Latvian');

  let format = 'DD.MM.YYYY HH:mm';
  flatpickr(".flatpickr", {
    enableTime: true,
    localize: 'Latvian',
    dateFormat: format,
    time_24hr: true,
    defaultHour: 12,
    disableMobile: true,
    allowInput: true,
    parseDate: (datestr, format) => {
      return moment(datestr, format, true).toDate();
    },
    formatDate: (date, format, locale) => {
      // locale can also be used
      return moment(date).format(format);
    }
  });

  let date_format = 'YYYY-MM-DD';
  flatpickr(".datepicker", {
    localize: 'Latvian',
    dateFormat: date_format,
    allowInput: true,
    disableMobile: true,
    parseDate: (datestr, format) => {
      return moment(datestr, date_format, true).toDate();
    },
    formatDate: (date, format, locale) => {
      // locale can also be used
      return moment(date).format(date_format);
    }
  });

  $.fn.BeerSlider = function ( options ) {
    options = options || {};
    return this.each(function() {
      new BeerSlider(this, options);
    });
  };
  $('.beer-slider').BeerSlider({start: 40});


  // $('.preloader').addClass('preloader-deactivate');
  $('#page_is_wedding_date_set').change();
});



// function initCounters() {
//   if ($(".counter").length) {
//     $(".counter").each(function(i, el) {
//       new Waypoint({
//         element: el,
//         handler: function() {
//           counterUp(el);
//           this.destroy();
//         },
//         offset: "bottom-in-view"
//       });
//     });
//   }
// }

$(document).on("change", "#page_is_wedding_date_set", function(){
  if(this.checked) {
    $('#page_wedding_date').attr( "disabled", 'disabled' );
  }
  else {
    $('#page_wedding_date').removeAttr('disabled');
  }
})

// $(document).on('click', '[data-loading]', function(){
//   $(this).attr('disabled', 'disabled')
//   $(this).find('.fa:first').attr('class', 'fa-refresh fa-spin')
// })

function verificationForm(){
  if ($(".multi_step_form").length) {
    var current_fs, next_fs, previous_fs; //fieldsets
    var left, opacity, scale; //fieldset properties which we will animate
    var animating; //flag to prevent quick multi-click glitches

    $(".next").click(function () {
        if (animating) return false;
        animating = true;

        current_fs = $(this).parent();
        next_fs = $(this).parent().next();

        //activate next step on progressbar using the index of next_fs
        $("#progressbar li").eq($(".multi-step-fomr-fieldset").index(next_fs)).addClass("active");

        //show the next fieldset
        next_fs.show();
        //hide the current fieldset with style
        current_fs.animate({
            opacity: 0
        }, {
            step: function (now, mx) {
                //as the opacity of current_fs reduces to 0 - stored in "now"
                //1. scale current_fs down to 80%
                scale = 1 - (1 - now) * 0.2;
                //2. bring next_fs from the right(50%)
                left = (now * 50) + "%";
                //3. increase opacity of next_fs to 1 as it moves in
                opacity = 1 - now;
                current_fs.css({
                    'transform': 'scale(' + scale + ')',
                    'position': 'absolute'
                });
                next_fs.css({
                    'left': left,
                    'opacity': opacity
                });
            },
            duration: 800,
            complete: function () {
                current_fs.hide();
                animating = false;
            },
            //this comes from the custom easing plugin
            easing: 'easeInOutBack'
        });
    });

    $(".previous").click(function () {
        if (animating) return false;
        animating = true;

        current_fs = $(this).parent();
        previous_fs = $(this).parent().prev();

        //de-activate current step on progressbar
        $("#progressbar li").eq($(".multi-step-fomr-fieldset").index(current_fs)).removeClass("active");

        //show the previous fieldset
        previous_fs.show();
        //hide the current fieldset with style
        current_fs.animate({
            opacity: 0
        }, {
            step: function (now, mx) {
                //as the opacity of current_fs reduces to 0 - stored in "now"
                //1. scale previous_fs from 80% to 100%
                scale = 0.8 + (1 - now) * 0.2;
                //2. take current_fs to the right(50%) - from 0%
                left = ((1 - now) * 50) + "%";
                //3. increase opacity of previous_fs to 1 as it moves in
                opacity = 1 - now;
                current_fs.css({
                    'left': left
                });
                previous_fs.css({
                    'transform': 'scale(' + scale + ')',
                    'opacity': opacity
                });
            },
            duration: 800,
            complete: function () {
                current_fs.hide();
                animating = false;
            },
            //this comes from the custom easing plugin
            easing: 'easeInOutBack'
        });
    });

    $(".submit").click(function () {
        return false;
    })
  }
};


function stepForm(){
  $(document).on("click", ".image-select-item", function(){
    let id = $(this).data('id');
    $('.image-select-item').removeClass('selected-image-select-item');
    $(this).addClass('selected-image-select-item');
    console.log('d', id);
    $("#page_marriage_state").val(id);
  });
}

function copyUrl(){
  $(document).on("click", "#copy", function(){
    var toCopy = $( '#copy-text' ).select();
    document.execCommand("copy");
    var btnCopy = $( '#copy' );
    btnCopy.addClass('copied');
    // toastr.info('Saite nokopēta!');

    var temp = setInterval( function(){
      btnCopy.removeClass( 'copied' );
      clearInterval(temp);
    }, 600 );

  })
}


function initSortable() {
  if ($("#sortable").length) {
    $("#sortable").sortable({
      handle: '.handle',
      scrollSpeed: 40,
      items: ".sortable-item:not(.unsortable)",
      cancel: 'unsortable',
      tolerance: 'pointer',
      cursor: "move",
      opacity: 0.7,
      animation: 200,
      containment: 'parent',
      axis:'y',
      placeholder: "ui-state-highlight",
      update: function(e, ui) {
        $.ajax({
          url: $(this).data("url"),
          type: "PATCH",
          data: $(this).sortable('serialize'),
        });
      }
    });


  }
}

function updateSectionMenu() {
  $.ajax({
    url: '/sections/reload_menu.js',
    method: 'POST',
    data: { },
    success: function(data, status) {

    }
  });
}

$(document).on("click", ".SectionVisibleSwitch", function(){
  let id = $(this).data("id");
  var att = $(this).attr("attribute");
  console.log('attributeX', att);

  if($(this).is(':checked')) {
    var value = true;
    $(this).closest('.entity-item').removeClass('hidden-entity-item');
  }
  else {
    var value = false;
    $(this).closest('.entity-item').addClass('hidden-entity-item');
  }

  let section = {}
  section [''+ att + ''] = value;

  $.ajax({
    url: '/sections/'+ id +'.js',
    method: 'PATCH',
    data: { id: id, section },
    cache: false
  });
})

$(document).on("click", "#TasksIsShowCompleted", function(){
  if($(this).is(':checked')) {
    var value = true;
  }
  else {
    var value = false;
  }

  let month_value = $('#tasks-month-filter').val();
  let creator_value = $('#tasks-creator-filter').val();
  let category_value = $('#tasks-category-filter').val();
  let search_value = $('#task-search').val();

  $.ajax({
    url: '/tasks/toggle_show_completed.js',
    method: 'PATCH',
    data: { value: value,
            month_value: month_value,
            creator_value: creator_value,
            category_value: category_value,
            search_value: search_value
          },
    cache: false
  });
})

$(document).on("click", "#ComplatedVendorCatogoriesIsShow", function(){
  if($(this).is(':checked')) {
    var value = true;
  }
  else {
    var value = false;
  }

  let id = $('.vendor_category-item.active my-vendor_category-trigger').data('id');

  $.ajax({
    url: '/user_vendors/toggle_vendor_categories.js',
    method: 'GET',
    data: { vendor_id: id, value: value },
    cache: false
  });
})

$(document).on("click", "#ShowVendorCategory", function(){
  let $this = $(this);
  let id = $this.data('id');
  if($this.is(':checked')) {
    var value = true;
    console.log('redzams');
    $('.vendor-template-item[data-id='+ id +']').addClass('category-visible').removeClass('category-hidden');
  }
  else {
    var value = false;
    console.log('slēpts');
    $('.vendor-template-item[data-id='+ id +']').removeClass('category-visible').addClass('category-hidden');
  }

  $.ajax({
    url: '/user_vendor_templates/'+ id +'/toggle_is_visible.js',
    method: 'GET',
    data: { value: value },
    cache: false
  });
})

$(document).on('click', "[data-target='#myModal']", function(ev) {
  if ($(ev.target).hasClass( "skip-modal" )) return;
  ev.preventDefault();
  let target = $(this).attr("href");
  call_modal(target, "layout=popup");
});

$(document).on('click', ".destroy-link", function(ev) {
  ev.preventDefault();
  let target = $(this).attr("href");
  call_modal(target, "layout=popup");
});

function call_modal(target, data, wrapper_id=null) {
  let $wrapper = $('#myModal');
  if (wrapper_id){
    $wrapper = $('#'+ wrapper_id);
  }

  console.log('$wrapper', $wrapper.attr('id'))

  var content = $(".modal-content", $wrapper);
  var loader = $(".loader", $wrapper);

  console.log('content', content);

  content.empty();
  $wrapper.modal("show");
  content.load(target, data, function(response, status, xhr) {
    if ( status == "error" ) {
      var icon = '<i class="fa fa-exclamation-triangle fa-3x text-red error_icon"></i>';
      loader.find('.fa').hide();
      loader.append(icon);
    } else {
      var height = content.height();
      // loader.find('.fa').show();
      // content.css({height: 500, overflow: 'auto'});
      // init_modal_preloads();
      loader.animate({height: height}, 500, function() {
        content.css({height: '', overflow: ''});
        loader.addClass('loaded')
      })
    }
  })
}

// $(document).on('click', "[data-target='#myModal']", function(ev) {
//   ev.preventDefault();
//   let target = $(this).attr("href");
//   call_modal(target, "layout=popup");
// });

$(document).on("keyup keydown", function(e) {
  if ((e.ctrlKey && !e.altKey) && e.keyCode === 83) {
    e.preventDefault();
    if ($(".save-button").length) {
      $(".save-button:visible").closest("form").submit();
    }
    return false;
  }
});

function start_loading(element) {
  console.log('element', element);
  $('' + element + '').append("<div class='loader-overlay'><div class='loader'></div></div>");
}

function stop_loading(element) {
  $(element).children(".loader-overlay").fadeOut(800).hide(800);
}

$(document).on('click', ".theme-color-item-wrapper", function(ev) {
  let $this = $(this);
  let color_id = $this.data("id");
  $.ajax({
    url: '/themes/load_color.json',
    data: { color_id: color_id },
    success: function(data, status) {
      $this.siblings().removeClass('selected-theme-color');
      $this.addClass('selected-theme-color');
      let $image = $this.closest('.theme-card').find('.card-img-top');
      $image.attr('src', data.img_src);
      $('.current-theme-wrapper').addClass('d-none');
      $('.choose-theme-wrapper').removeClass('d-none');
    },
    cache: false
  });
});

$(document).on('click', ".set-theme-action", function(ev) {
  let $this = $(this);
  let color_id = $this.closest('.theme-card').find('.selected-theme-color').data("id");
  console.log('color_id', color_id);
  $.ajax({
    url: '/pages/set_theme.js',
    data: { color_id: color_id },
    success: function(data, status) {
      $('.current-theme-wrapper').addClass('d-none');
      $('.choose-theme-wrapper').removeClass('d-none');
      $this.closest('.theme-card').find('.current-theme-wrapper').removeClass('d-none');
      $this.closest('.theme-card').find('.choose-theme-wrapper').addClass('d-none');
      if (data.message) {
        toastr.info(data.message);
      }
      if (data.alert) {
        toastr.alert(data.alert);
      }
    },
    cache: false
  });
});

$(document).on("click", ".guest-type-option", function(){
  let $this = $(this);
  let value = $this.data('type');
  console.log('value', value);

  $.ajax({
    url: '/guests/set_form_type.js',
    data: { value: value },
    success: function(data, status) {
      $this.siblings().removeClass('btn-secondary');
      $this.siblings().addClass('btn-outline-secondary');
      $this.addClass('btn-secondary');
      $this.removeClass('btn-outline-secondary');
    },
    cache: false
  });
});

function initDatatable() {
  $('#guests-datatable').dataTable({
    "processing": true,
    "serverSide": true,
    "ajax": {
      "url": $('#guests-datatable').data('source')
    },
    "pagingType": "full_numbers",
    "columns": [
      {"data": "relationship"},
      // {"data": "first_name"},
      // {"data": "last_name"},
      // {"data": "email"},
      // {"data": "bio"}
    ]
    // pagingType is optional, if you want full pagination controls.
    // Check dataTables documentation to learn more about
    // available options.
  });
}


function initToggleChecks() {
  $("#toggle-all-checks").change(function() {
      if(this.checked) {
        $('.form-simple-check-input').prop( "checked", true );
      } else {
        $('.form-simple-check-input').prop( "checked", false );
        toggleBulkActions()
      }
  });

  $(".form-simple-check-input").change(function() {
    toggleBulkActions();
  });
  toggleBulkActions();
}

function toggleBulkActions() {
  $("#guest_list-wrapper .form-simple-check-input")
  let numberOfChecked = $('#guest_list-wrapper .form-simple-check-input:checked:not(#toggle-all-checks)').length;
  if (numberOfChecked > 0) {
    $("#guest-index-bulk-actions-wrapper").show().addClass('d-inline-flex');
    $(".multi-select-container").hide();
    $("#guest-search").hide();
    $("#selected-guest-count").html(numberOfChecked);
  } else {
    $("#guest-index-bulk-actions-wrapper").hide().removeClass('d-inline-flex');
    $(".multi-select-container").show();
    $("#guest-search").show();
  }
}

$(document).on("click", ".remote-form-check-input", function(){
  let id = $(this).data('id');
  let is_definitely_invited = $(this).attr('value');
  let guest_group = {}
  guest_group['is_definitely_invited'] = is_definitely_invited;

  $.ajax({
    url: '/guests/' + id + '.js',
    method: 'PATCH',
    data: { guest_group },
    success: function(data, status) {
      // toastr.info('Saglabāts');
    },
    cache: false
  });
});


function initGuestSearch() {
  $('#guest-index-filter').change(function(){
    let filter_values = $(this).val();
    let search_value = $('#guest-search').val();
    console.log('values', [filter_values, search_value]);

    $.ajax({
      url: '/guests/search.js',
      data: { filter_values: filter_values, search_value: search_value },
      success: function(data, status) {

      },
      cache: false
    });

  });

  $('#guest-search').on("change keyup",function() {
    let filter_values = $('#guest-index-filter').val();
    let search_value = $(this).val();
    console.log('values', [filter_values, search_value]);

    $.ajax({
      url: '/guests/search.js',
      data: { filter_values: filter_values, search_value: search_value },
      success: function(data, status) {

      },
      cache: false
    });
  })
}

$(document).on("click", ".guest-bulk-action", function(){
  let action = $(this).data("bulk");
  let IDs = [];

  $(".form-simple-check-input:checked:not(#toggle-all-checks)").each(function(){ IDs.push(this.value); });

  if (action == 'qrcodes'){
    let url = '/guests/bulk_actions/?ids=' + IDs +'&action_type=' + action
    window.location.href = url
  } else {
    $.ajax({
      url: '/guests/bulk_actions.js',
      method: 'GET',
      data: { ids: IDs, action_type: action },
      cache: false
    });
  }
})

$(document).on("click", ".invitation-bulk-action", function(e){
  let action = $(this).data("bulk");
  let IDs = [];

  $(".form-simple-check-input:checked:not(#toggle-all-checks)").each(function(){ IDs.push(this.value); });

  if (action == 'send_message'){
    e.preventDefault();
    let url = '/invitation_guests/message_form?ids=' + IDs +'.js'
    call_modal(url, "layout=popup");
    // window.location.href = url
  } else {
    $.ajax({
      url: '/invitation_guests/bulk_actions.js',
      method: 'GET',
      data: { ids: IDs, action_type: action },
      cache: false
    });
  }
})

$(document).on("click", ".todo_list-trigger", function(){
  let $this = $(this);
  let id = $this.data('id');

  $('#todo_list-sidebar .todo_list-item').removeClass('active');
  $this.parents('.todo_list-item').addClass('active');
  console.log('id', id)
  $.ajax({
    url: '/todo_lists/' + id + '/set_current_todo_list.js',
    method: 'GET',
    data: { todo_list_id: id },
    success: function(data, status) {
    },
    cache: false
  });
});

$(document).on("click", ".budget-trigger", function(){
  let $this = $(this);
  let id = $this.data('id');

  $('#budget-sidebar .budget-item').removeClass('active');
  $this.parents('.budget-item').addClass('active');
  console.log('id', id)
  $.ajax({
    url: '/budgets/' + id + '/set_current_budget.js',
    method: 'GET',
    data: { budget_id: id },
    success: function(data, status) {
    },
    cache: false
  });
});

$(document).on("click", ".vendor_category-trigger", function(){
  let $this = $(this);
  let category = $this.data('id');

  $('#vendor-list').addClass('loadingOverlay');

  $('.vendor_category-item').removeClass('active');
  $this.parents('.vendor_category-item').addClass('active');
  $.ajax({
    url: '/pakalpojumus-sniedzeji/' + category + '.js',
    method: 'GET',
    data: { category: category },
    success: function(data, status) {
      $('#vendor-list').removeClass('loadingOverlay');
    },
    cache: false
  });
});


// $(document).on("click", ".task-checkbox", function(){
//   let id = $(this).attr('value');
//   let todo_list_id = $(this).data('todo_list_id');
//   if($(this).is(':checked')) {
//     var is_checked = true;
//   }
//   else {
//     var is_checked = false;
//   }

//   let task = {}
//   task ['is_done'] = is_checked;
//   task ['todo_list_id'] = todo_list_id;

//   $.ajax({
//     url: '/todo_lists/' + todo_list_id + '/tasks/'+ id +'.js',
//     method: 'PATCH',
//     data: { id: id, task },
//     cache: false
//   });
// });

$(document).on("click", "#tasks-table .task-checkbox", function(){
  let id = $(this).attr('value');
  let $row = $('.task-row[data-task_id="' + id + '"]');
  if($(this).is(':checked')) {
    var is_checked = true;
    $row.addClass('task-done');
    if($('#tasks-table').length){
      shrinkTableRow($row)
    }
    if($('#TasksIsShowCompleted').length){
      if(!$('#TasksIsShowCompleted').is(':checked')) {
        shrinkTableRow($row)
      }
    }
  }
  else {
    var is_checked = false;
     $row.removeClass('task-done');
  }

  let task = {}
  task ['is_done'] = is_checked;
  // task ['todo_list_id'] = todo_list_id;

  $.ajax({
    url: '/tasks/'+ id +'/toggle.js',
    method: 'PATCH',
    data: { id: id, task, toggle: is_checked },
    cache: false
  });
});

$(document).on("click", ".task-grid-item .task-checkbox", function(){
  let id = $(this).attr('value');
  let $parent = $('.task-grid-item-wrapper[data-task_id="' + id + '"]');
  if($(this).is(':checked')) {
    var is_checked = true;
    $parent.addClass('task-done');

    if($('#TasksIsShowCompleted').length){
      if(!$('#TasksIsShowCompleted').is(':checked')) {
        $parent.fadeOut( "slow", function() {
          $parent.remove();
        })
      }

      if ($parent.parents('.task-list-wrapper').find('.task-grid-item-wrapper').length == 1){
        $parent.parents('.task-list-wrapper').slideUp(500).hide(300);
      }
    }
  }
  else {
    var is_checked = false;
     $parent.removeClass('task-done');
  }



  let task = {}
  task ['is_done'] = is_checked;

  $.ajax({
    url: '/tasks/'+ id +'/toggle.js',
    method: 'PATCH',
    data: { id: id, task, toggle: is_checked },
    cache: false
  });
});



function initDatepicker() {
  let date_format = 'YYYY-MM-DD';
  flatpickr(".datepicker", {
    localize: 'Latvian',
    dateFormat: date_format,
    disableMobile: true,
    allowInput: true,
    parseDate: (datestr, format) => {
      return moment(datestr, date_format, true).toDate();
    },
    formatDate: (date, format, locale) => {
      return moment(date).format(date_format);
    }
  });
}


function shrinkTableRow(row) {
  // console.log('row', row)
  let $row = row;
  if ($row.parents('.task-list-wrapper').find('.task-row').length == 1){
    $row.parents('.task-list-wrapper').slideUp(500).hide(300);
  }
  $row.find('td').each(function(index, element) {

      // Wrap each td inside the selected tr in a temporary div
      $(this).wrapInner('<div class="td_wrapper"></div>');

      // Fold the table row
      $(this).parent().find('.td_wrapper').each(function(index, element) {

          // SlideUp the wrapper div
          $(this).slideUp(500);

          // Remove padding from each td inside the selected tr
          $row.find('td').each(function(index, element) {
            $(this).animate({
              'padding-top': '0px',
              'padding-bottom': '0px'
            }, function() {
              $row.remove();
            });
          });

      });
  });
}



function initCalendar() {
  if ($("#calendar").length) {
    var calendarEl = document.getElementById('calendar');
    var calendar = new Calendar(calendarEl, {
      events: {
        url: '/tasks/feed.json'
      },
      height: 700,
      eventLimit: 6,
      header: {
        left: 'prev,next today',
        center: 'title',
        right: 'month,listWeek',
      },
      locale: calLocaleLv,
      plugins: [ dayGridPlugin, listPlugin ],
      defaultView: 'dayGridMonth',
      eventRender: function(event, element) {
          element
              .attr('title', 'fooXXX')
              .tooltip();
      }
    });

    calendar.render();
  }
}


function initTables() {
  initDragablle();
  initDroppable();
  initDroppableGuest();
  initSeatDragabble();
  // initSeats();
}


function initDragablle() {
  $( ".enable-edit .draggable-item" ).draggable({
    containment: "#draggable-conainer",
    start: function() {

    },
    drag: function() {

    },
    stop: function() {
      let $this = $(this);
      let id = $this.data('id');
      let table = {}
      var finalOffset = $this.offset();
      var finalxPos = $this.position().left;
      var finalyPos = $this.position().top;
      table['posx'] = finalxPos;
      table['posy'] = finalyPos;

      $this.find('.table-coords').html(finalxPos + finalyPos);

      $.ajax({
        url: '/tables/' + id + '.json',
        method: 'PATCH',
        data: { table },
        success: function(data, status) {

        },
        cache: false
      });

      initTables();

    }
  });
}

function initDroppable() {
  $('.new-table-item').draggable({
      helper : "clone"
     });
   $('#draggable-conainer').droppable({
       accept      : ".new-table-item",
       drop        : function(event,ui)
      {
        let $this = $(this);
        let type = ui.helper[0].dataset.type;
        // let position = $this.position;
        let posy = (ui.position.top - $this.offset().top);
        let posx = (ui.position.left - $this.offset().left);
        console.log('0', ui.helper[0].dataset.type);
        console.log('first', ui.helper.first);
        console.log('posy', posy);
        console.log('posx', posx);
        // console.log('position', position);
         var dropedItem = $(ui.draggable).clone();
         // dropedItem.addClass('draggable-item').removeClass('new-table-item').css({'top': posy, 'left': posx});
         // console.log(dropedItem.html());
         // $this.append(dropedItem);

        let target = '/tables/new?posx='+ posx +'&posy='+ posy +'&type='+ type +'';

        call_modal(target, "layout=popup");

        // $.ajax({
        //   url: '/tables.js',
        //   method: 'POST',
        //   data: { table },
        //   success: function(data, status) {

        //   },
        //   cache: false
        // });

         initTables();
      }
  });
}

function initDroppableGuest() {
  $('.sidebar_guest-item').draggable({
    containment: "document",
    cursorAt: { top: 5, left: 5 },
    // appendTo: "#table-container",
    helper: function (e) {
      return '<div class="pulse pulsating-circle"><i class="pulse-icon '.concat('fa fa-user', '"></i></div>');
    },

   });
   $('.table-seat').droppable({
      accept: ".sidebar_guest-item, .enable-edit .seat-guest-item",
      tolerance: 'pointer',
      classes: {"ui-droppable-hover": "ui-table-hover"},
      out: function(event,ui){
          // console.log('out event', event);
          // console.log('out ui', ui);
      },
      drop: function(event,ui)
      {
        let $this = $(this);
        let seat_id = $this.data('id')
        let guestid = ui.draggable[0].dataset.guestid
        // let position = $this.position;
        console.log('ui.draggable', ui.draggable);
        console.log('guestid', guestid);
        console.log('seat_id', seat_id);
        // console.log('posy', posy);
        // console.log('posx', posx);
        // console.log('position', position);
         // var dropedItem = $(ui.draggable).clone();
         // dropedItem.addClass('draggable-item').removeClass('new-table-item').css({'top': posy, 'left': posx});
         // console.log(dropedItem.html());
         // $this.append(dropedItem);


        $.ajax({
          url: '/tables/add_guest_to_seat.json',
          method: 'GET',
          data: { seat_id: seat_id, guestid: guestid },
          success: function(data, status) {
            // console.log('datiņi', [data, status])

            if (data.guestid) {
              let html = '<div class="seat-guest-item" data-guestid='+ data.guestid +'><span class="seat-guest-title">' + data.guest_name + '</span><i class="fa ' + data.guest_type + ' guest-icon"></i></div>'
              $this.addClass('seat-with-guest');
              $this.append(html);
              $('.sidebar_guest-item[data-guestid='+ guestid +']').remove();
              $(ui.helper).remove();
            } else {
              $this.removeClass('seat-with-guest');
            }
            initTables();
          },
          cache: false
        });

         initTables();
      }
  });
}

function initSeatDragabble() {
  $('.enable-edit .seat-guest-item').draggable({
    containment: "document",
    revert: function(valid) {
        if(!valid) {
          // console.log('valid this', $(this)[0].dataset.guestid)

          let guestid = $(this)[0].dataset.guestid;

          $.ajax({
            url: '/tables/remove_guest_from_seat.js',
            method: 'GET',
            data: { guestid: guestid },
            success: function(data, status) {
              $('.seat-guest-item[data-guestid='+ guestid +']').remove();
            },
            cache: false
          });


        }
    },
    // cursorAt: { top: 2, left: 2 },
    // helper: function (e) {
    //   console.log('e', e.currentTarget)

    //   return '<div class="pulse pulsating-circle"><i class="pulse-icon '.concat('fa fa-user', '"></i></div>');

    // },

    start: function(event, ui) {
        // ui.helper.data('dropped', false);
    },
    stop: function(event, ui)
    {
        // alert('stop: dropped=' + ui.helper.data('dropped'));
        // Check value of ui.helper.data('dropped') and handle accordingly...
      // console.log('ui.helper', ui.helper);
      // console.log('ui.helper.data', ui.helper.data);
     }
    // appendTo: "#table-container",
    // helper : "clone",
    // helper: function (e) {
    //   console.log('e', e)
    //   return '<div class="pulse"><i class="pulse-icon '.concat('fa fa-user', '"></i></div>');
    // },

   });
 }

function initSeats() {
  $(".circle-table").each(function(i, el) {
    let $this = $(this);
    let radius = 90;
    let fields = $this.find('.table-seat'), container = $this, width = (container.width() + 30), height = (container.height() + 30);
    let angle = 0, step = (2*Math.PI) / fields.length;
    fields.each(function() {
        var x = Math.round(width/2 + radius * Math.cos(angle) - $(this).width()/2);
        var y = Math.round(height/2 + radius * Math.sin(angle) - $(this).height()/2);
        if(window.console) {
            // console.log($(this).text(), x, y);
        }
        $(this).css({
            left: x + 'px',
            top: y + 'px'
        });
        angle += step;
    });


  })


  // var radius = 90;
  // var fields = $('#table-43 .table-seat'), container = $('#table-43'), width = (container.width() + 30), height = (container.height() + 30);
  // var angle = 0, step = (2*Math.PI) / fields.length;
  // fields.each(function() {
  //     var x = Math.round(width/2 + radius * Math.cos(angle) - $(this).width()/2);
  //     var y = Math.round(height/2 + radius * Math.sin(angle) - $(this).height()/2);
  //     if(window.console) {
  //         console.log($(this).text(), x, y);
  //     }
  //     $(this).css({
  //         left: x + 'px',
  //         top: y + 'px'
  //     });
  //     angle += step;
  // });


}


$(window).on('load', function() {
    //for use in production please remove this setTimeOut
    // setTimeout(function(){
    //     $('.preloader').addClass('preloader-deactivate');
    // }, 3000);
    //uncomment this line for use this snippet in production
    // $('.preloader').addClass('preloader-deactivate');
});

function initTaskSearch() {
  $('#tasks-month-filter, #tasks-creator-filter, #tasks-category-filter').change(function(){
    let month_value = $('#tasks-month-filter').val();
    let creator_value = $('#tasks-creator-filter').val();
    let category_value = $('#tasks-category-filter').val();
    let search_value = $('#task-search').val();
    console.log('values', [month_value, creator_value, search_value]);

    $.ajax({
      url: '/tasks/search.js',
      data: {
              month_value: month_value,
              creator_value: creator_value,
              category_value: category_value,
              search_value: search_value
            },
      success: function(data, status) {

      },
      cache: false
    });

  });

  $('#task-search').on("change keyup",function() {
    let month_value = $('#tasks-month-filter').val();
    let creator_value = $('#tasks-creator-filter').val();
    let category_value = $('#tasks-category-filter').val();
    let search_value = $('#task-search').val();

    $.ajax({
      url: '/tasks/search.js',
      data: {
        month_value: month_value,
        creator_value: creator_value,
        category_value: category_value,
        search_value: search_value
      },
      success: function(data, status) {

      },
      cache: false
    });
  })
}

function hideFlashMessage() {
  if ($(".flash-notification").length) {
    setTimeout(function(){ $(".flash-notification").hide(500) }, 8000);
  }
}


// function crop_image_load(data) {
//     data = data.detail;
//     var $crop_x = $("input#logo_crop_x")[0],
//         $crop_y = $("input#logo_crop_y")[0],
//         $crop_w = $("input#logo_crop_w")[0],
//         $crop_h = $("input#logo_crop_h")[0];
//     $crop_x.value = data.x;
//     $crop_y.value = data.y;
//     $crop_h.value = data.height;
//     $crop_w.value = data.width;
// }

// function getRoundedCanvas(sourceCanvas) {
//     var canvas = document.createElement('canvas');
//     var context = canvas.getContext('2d');
//     var width = sourceCanvas.width;
//     var height = sourceCanvas.height;
//     canvas.width = width;
//     canvas.height = height;
//     context.imageSmoothingEnabled = true;
//     context.drawImage(sourceCanvas, 0, 0, width, height);
//     context.globalCompositeOperation = 'destination-in';
//     context.beginPath();
//     context.arc(width / 2, height / 2, Math.min(width, height) / 2, 0, 2 * Math.PI, true);
//     context.fill();
//     return canvas;
// }

// function readURL(input) {
//     if (input.files && input.files[0]) {
//         var reader = new FileReader();
//         reader.onload = function(e) {
//             $('#image').attr('src', e.target.result);
//         }
//         reader.readAsDataURL(input.files[0]);
//     }
// }

// function initCropper() {
//   var $crop_x = $("input#logo_crop_x"),
//       $crop_y = $("input#logo_crop_y"),
//       $crop_w = $("input#logo_crop_w"),
//       $crop_h = $("input#logo_crop_h");
//   $crop_x.val('');
//   $crop_y.val('');
//   $crop_h.val('');
//   $crop_w.val('');
//   var $image = $('#image')[0];
//   var $button = $('#button');
//   var croppable = false;
//   let x;
//   $('#upload-modal').on('shown.bs.modal', function() {
//       x = new Cropper($image, {
//           aspectRatio: 3 / 1,
//           viewMode: 1,
//           ready: function() {
//               croppable = true;
//           },
//           crop: function(event) {
//               crop_image_load(event)
//           }
//       });
//   }).on('hidden.bs.modal', function() {
//       x.destroy();
//   });

//   $button.on('click', function() {
//       var croppedCanvas;
//       var roundedCanvas;
//       croppedCanvas = x.getCroppedCanvas();
//       // roundedCanvas = getRoundedCanvas(croppedCanvas);
//       // $('#section_cover')[0].src = roundedCanvas.toDataURL();
//       $('#section_cover')[0].src = croppedCanvas.toDataURL();
//       // $('#avatar')[0].setAttribute("height", "100");
//       // $('#avatar')[0].setAttribute("width", "100");
//       $('#upload-modal').modal('hide');
//   });
//   $("#image_to_upload").change(function() {
//       $('#upload-modal').modal('show');
//       readURL(this);
//   });
// }



// $(document).on("click", "#vendor-category-input", function(){
//   alert('Keep coding here!');
//   let $this = $(this);
//   // $this.
//   let category = $this.data('id');

//   $('#vendor-list').addClass('loadingOverlay');

//   $('#vendor-categories .vendor_category-item').removeClass('active');
//   $this.parents('.vendor_category-item').addClass('active');
//   $.ajax({
//     url: '/pakalpojumus-sniedzeji/' + category + '.js',
//     method: 'GET',
//     data: { category: category },
//     success: function(data, status) {
//       $('#vendor-list').removeClass('loadingOverlay');
//     },
//     cache: false
//   });
// });





$(document).on("click", ".apply-filter", function(){
  triggerVendorSearch();
})

$(document).on("change", "#vendor-category-input", function(){
  // $('.vendor-filter-check:checkbox').removeAttr('checked');
  // triggerVendorSearch();
  let category_value = $('#vendor-category-input').val();
  let url = '/pakalpojumu-sniedzeji/' + category_value;
  window.location.href = url
})

$(document).on("change", "#vendor-date-input", function(){
  triggerVendorSearch();
})


$(document).on("change keyup paste", "#vendor-search-input", function(){
  // if ($(this).val().length > 2) {
    triggerVendorSearch();
  // }
})

$(document).on("click", ".vendor-filter-search-tag", function(){
  $('#vendor-search-input').val('');
  triggerVendorSearch();
})

$(document).on("click", ".vendor-filter-key-tag", function(){
  let key = $(this).data('key');
  $('#' + key).removeAttr('checked');
  triggerVendorSearch();
})

$(document).on("click", ".vendor-filter-date-tag", function(){
  // const $flatpickr = $("#vendor-date-input").flatpickr().clear();

  // $flatpickr.clear();
  setTimeout(function(){
    $("#vendor-date-input").flatpickr().clear();
  }, 100);
  triggerVendorSearch();
})

function triggerVendorSearch() {
  let date_value = $('#vendor-date-input').val();
  let category_value = $('#vendor-category-input').val();
  let search_value = $('#vendor-search-input').val();
  let FilterKeys = [];

  $('.vendor-filter-wrapper').removeClass('active');
  $(".vendor-filter-check:checked").each(function(){
    var $this = $(this);
    console.log($this.attr('group'));

    var group = $this.attr('group');
    var id = this.id;

    // FilterKeys.push([group, id]);


    FilterKeys.push([group, id]);

    $(this).parents('.vendor-filter-wrapper').addClass('active');
  });
  // console.log('values', [date_value, category_value, search_value]);
  console.log('FilterKeys', FilterKeys);

  // let filter_params = $.query.set('keys', FilterKeys);

  // window.location.href = '/pakalpojumu-sniedzeji/all?' + filter_params

  // FilterKeys.each(function(key) {
  //   console.log('key', key);

  let url = '/pakalpojumu-sniedzeji/' + category_value;
  if (search_value.length) {
    url = url + '?search_value=' + search_value
  }

  if (FilterKeys.length) {
    $.each(FilterKeys, function( i, data ) {
      console.log('data', data[0])
      if ( i === 0) {
        url = url + '?keys[]=' + data
      } else {
        url = url + '&keys[]=' + data
      }

    });
  }

  // console.log('url', url);


// ?keys[]=caterers_service_type_swedish_table&keys[]=caterers_service_type_guest_tables



  // window.location.href = url


  // var refresh = window.location.protocol + "//" + window.location.host + window.location.pathname + '?arg=1';
  let refresh = url
  window.history.pushState({ path: refresh }, '', refresh);


  $.ajax({
    url: '/vendors.js',
    data: {
            date_value: date_value,
            category_value: category_value,
            search_value: search_value,
            keys: FilterKeys
          },
    success: function(data, status) {

    },
    cache: false
  });

};

$(document).on("change", "#new-vendor-category", function(){
  let category = $(this).val();
  window.location.href = '/vendors/new?category=' + category
})


function initSwiper() {
  $(".swiper-container").each(function() {
    var $this = $(this);
    var boolData = {
      breakpoints: $this.data("sw-breakpoints"),
      active_selector: $this.data("sw-active-selector"),
      cover_flow: $this.data("sw-coverflow"),
      auto_play: $this.data("sw-autoplay"),
      loop: $this.data("sw-loop"),
      centered: $this.data("sw-centered-slides"),
      pagination: $this.data("sw-pagination"),
      nav_arrows: $this.data("sw-nav-arrows"),
      dynamicBullets: $this.data("sw-dynamic-bullets")
    };

    var breakPoints = boolData.breakpoints || false;
    var auto_play =
      boolData.auto_play !== undefined ? boolData.auto_play : false;
    var speed = $this.data("sw-speed") || 1100;
    var effect = $this.data("sw-effect") || "slide";
    var showItems = $this.data("sw-show-items") || 1;
    var loop = boolData.loop !== undefined ? boolData.loop : true;
    var centered = boolData.centered !== undefined ? boolData.centered : true;
    var dynamicBullets =
      boolData.dynamicBullets !== undefined ? boolData.dynamicBullets : true;
    var spaceBetween =
      $this.data("sw-space-between") || (showItems > 1 ? 20 : 0);
    var scrollItems = $this.data("sw-scroll-items") || 1;
    var navigationElement = $this.data("sw-navigation");
    var navigationActiveClass =
      $this.data("sw-navigation-active") || "active";
    var navigationActiveSelector =
      boolData.active_selector !== undefined
        ? boolData.active_selector
        : false;
    var paginationCss =
      boolData.pagination !== undefined
        ? boolData.pagination
        : ".swiper-pagination";
    var navigationCss =
      boolData.nav_arrows !== undefined
        ? boolData.nav_arrows
        : ".swiper-button";

    var coverflow = boolData.cover_flow
      ? {
          coverflowEffect: $.extend(
            {
              stretch: 0,
              depth: 0,
              modifier: 1,
              rotate: 0,
              slideShadows: false
            },
            boolData.cover_flow
          )
        }
      : {};

    var autoplay = auto_play
      ? {
          autoplay: {
            delay: auto_play,
            disableOnIteration: false
          },
          speed: speed
        }
      : {};

    var pagination = {};

    if (paginationCss) {
      pagination.pagination = {
        el: paginationCss,
        clickable: true
      };

      /* if (dynamicBullets) {
                  pagination.pagination.dynamicBullets = dynamicBullets;
              } */
    }

    if (navigationCss) {
      pagination.navigation = {
        nextEl: navigationCss + "-next",
        prevEl: navigationCss + "-prev"
      };
    }

    var events = {};

    /**/ if (navigationElement) {
      //console.log("navigationElement swiper", navigationElement);
      events = {
        transitionEnd: function() {
          if (!navigationElement) return;

          var $navigationElement = $(navigationElement);

          if (navigationActiveSelector) {
            $(
              navigationActiveSelector + "." + navigationActiveClass,
              $navigationElement
            ).removeClass(navigationActiveClass);
            $(
              ".nav-item:eq(" +
                swiper.realIndex +
                ") " +
                navigationActiveSelector,
              $navigationElement
            ).addClass(navigationActiveClass);
          } else {
            $("." + navigationActiveClass, $navigationElement).removeClass(
              navigationActiveClass
            );
            $(
              ".nav-item:eq(" + swiper.realIndex + ")",
              $navigationElement
            ).addClass(navigationActiveClass);
          }
        }
      };
    } /**/

    var options = $.extend(
      {
        loop: loop,
        slidesPerGroup: scrollItems,
        spaceBetween: spaceBetween,
        centeredSlides: centered,
        breakpoints: breakPoints,
        slidesPerView: showItems,
        parallax: true,
        effect: effect
      },
      pagination,
      autoplay,
      coverflow
    );

    console.log("swiper options", options);
    var swiper = new Swiper(this, options);

    for (var e in events) {
      swiper.on(e, events[e]);
    }

    if (navigationElement) {
      $(navigationElement).on("click", ".nav-item", function(evt) {
        evt.preventDefault();

        var $item = $(this);
        var $activeItem = $item;

        if (navigationActiveSelector) {
          $activeItem = $(navigationActiveSelector, $item);
        }

        if ($activeItem.hasClass(navigationActiveClass)) {
          return false;
        }

        var index = $item.data("step") || $item.index() + 1;
        swiper.slideTo(index);

        if (navigationActiveSelector) {
          $item.siblings().each(function() {
            $(navigationActiveSelector, this).removeClass(
              navigationActiveClass
            );
          });

          $activeItem.addClass(navigationActiveClass);
        } else {
          $item
            .siblings("." + navigationActiveClass)
            .removeClass(navigationActiveClass);
          $item.addClass(navigationActiveClass);
        }

        return false;
      });
    }
  });
}

$(document).on("click", ".cookie-accept-link", function(){
  var cookie_url = $(this).attr('data-link');
  $.ajax({
    method: 'GET',
    url: '/cookie_accept',
    success: function (status) {
      $('.footer-cookies-terms-block').remove();
    }
  });
})

$(document).on("click", ".select-vision-btn", function(){
  let $this = $(this);
  let id = $this.data('id');
  let type = $this.data('select_type');

  if (type == '1') {
    $this.parents('.vision-item').removeClass('disliked');
    $this.parents('.vision-item').toggleClass('liked');
  }

  if (type == '0') {
    $this.parents('.vision-item').removeClass('liked');
    $this.parents('.vision-item').toggleClass('disliked');
  }

  $.ajax({
    method: 'GET',
    url: '/visions/' + id + '/create_user_reaction.js',
    data: { type: type },
    success: function (status) {

    }
  });
});

function initVendorTemplate() {

  // $( ".column" ).sortable({
  //   connectWith: ".column",
  //   handle: ".portlet-header",
  //   cancel: ".portlet-toggle",
  //   placeholder: "portlet-placeholder ui-corner-all"
  // });

  // $( ".portlet" )
  //   .addClass( "ui-widget ui-widget-content ui-helper-clearfix ui-corner-all" )
  //   .find( ".portlet-header" )
  //     .addClass( "ui-widget-header ui-corner-all" )
  //     .prepend( "<span class='ui-icon ui-icon-minusthick portlet-toggle'></span>");

  // $( ".portlet-toggle" ).on( "click", function() {
  //   var icon = $( this );
  //   icon.toggleClass( "ui-icon-minusthick ui-icon-plusthick" );
  //   icon.closest( ".portlet" ).find( ".portlet-content" ).toggle();
  // });

  // $( "#vendor-temaplte-wrapper" ).sortable({
  //   handle: ".vendor-template-handle",
  //   placeholder: 'sortable-placeholder col-md-3 rounded',
  //   stop: function(e, ui) {
  //     let arr = $( "#vendor-temaplte-wrapper" ).sortable( "toArray" );
  //     console.log("arr", arr);

  //     $.ajax({
  //       url: '/user_vendor_templates/sort.js',
  //       type: "PATCH",
  //       data: { ids: arr },
  //       cache: false
  //     });

  //   }
  // });
}

$(document).on("click", ".new-category-item", function(){
  let category = $(this).data('category');
  $.ajax({
    url: '/user_vendor_templates/add.js',
    data: { category: category },
    success: function (status) {

    }
  });
})


function initTopMenu() {
  let $navbar = $(".main-nav"),
    stickyPoint = 90;

  /** Perspective mockups reference **/
  let $perspectiveMockups = $(".perspective-mockups");

  // This element is used as reference for relocation of the mockups on mobile devices.
  // If you remove it please be sure you add another reference element preferably within the same section and/or position the button was.
  // You can change the selector (".learn-more") to one that uniquely identifies the reference element.
  let $topReference = $(".learn-more", ".lightweight-template");

  let setMockupsTop = function() {
    // check if the perspective mockups elements are on the page, if you're not going to use them, you can remove all its references
    if (!$perspectiveMockups.length) return;

    if ($(window).outerWidth() < 768) {
      $perspectiveMockups.css({ top: $topReference.offset().top + "px" });
      return;
    }

    $perspectiveMockups.removeAttr("style");
  };

  let navbarSticky = function() {
    if ($(window).scrollTop() >= stickyPoint) {
      $navbar.addClass("navbar-sticky");
    } else {
      $navbar.removeClass("navbar-sticky");
    }
  };

  $(window).on("scroll", navbarSticky);
  navbarSticky();
}






function initCocoonEvents() {

  let limit = 5;
  if($('#page-vision-form .page_colors-nested-fields:visible').length >= limit) {
    $("#page-vision-form a.add_fields").hide();
  }

  $('#page-vision-form').on('cocoon:after-insert', function(event, insertedItem) {
    let limit = 5;
    if($('#page-vision-form .page_colors-nested-fields:visible').length >= limit) {
      $("#page-vision-form a.add_fields").hide();
      // return
    }
  });

  $('#page-vision-form').on('cocoon:after-remove', function(event, insertedItem) {
    let limit = 5;
    if($('#page-vision-form .page_colors-nested-fields:visible').length < limit) {
      $("#page-vision-form a.add_fields").show();
      // return
    }
  });

  // initCocoonEvents = function(){};
}

function initCountdown() {
  $('#clock-b').countdown('2022/07/1').on('update.countdown', function(event) {
    var $this = $(this).html(event.strftime(''
      + '<div class="holder"><span class="countdown-value">%D</span> <span class="countdown-label">Diena%!d</span></div>'
      + '<div class="holder"><span class="countdown-value">%H</span> <span class="countdown-label">Stundas</span></div>'
      + '<div class="holder"><span class="countdown-value">%M</span> <span class="countdown-label">Minūtes</span></div>'
      + '<div class="holder d-none d-sm-block"><span class="countdown-value">%S</span> <span class="countdown-label">Sekundes</span></div>'));
  });
}


function initVendorMap() {
  if ($("#vendor_map").length) {

    var L = window.L;
    let coordinates = $('#vendor_map').data("current_loc");
    console.log('coordinates', coordinates)
    var icon = L.icon({
      iconUrl: "https://digi.wedding/pub_imgs/digi_pin.png",
      iconSize: [28, 41], // size of the icon
      popupAnchor: [13,-10],
      iconAnchor: [10, 35]
    })

    let map = L.map('vendor_map').setView(coordinates, 15);

    L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(map);

    L.marker(coordinates, { icon: icon }).addTo(map);
  }
}

// function initVendorIndexMap() {
//   if ($("#vendor_index_map").length) {

//     var L = window.L;
//     let coordinates = $('#vendor_map').data("current_loc");
//     console.log('coordinates', coordinates)
//     var icon = L.icon({
//       iconUrl: "https://digi.wedding/pub_imgs/digi_pin.png",
//       iconSize: [28, 41], // size of the icon
//       popupAnchor: [13,-10],
//       iconAnchor: [10, 35]
//     })

//     let map = L.map('vendor_map').setView(coordinates, 15);

//     L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
//         attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
//     }).addTo(map);

//     L.marker(coordinates, { icon: icon }).addTo(map);
//   }
// }







function initVendorIndexMap() {

  $( ".vendor-map-card" ).hover(
    function() {
      // console.log('in');
      let id = $( this ).data('id');
      // console.log('Hovered id', id);
      let location = $( this ).data('location');
      // let map = window.vendor_map;
      // console.log(['in', id]);
      // map = window.map;
      // window.map.eachLayer(function (layer) {
      //   if (layer.options.id === id) {
      //     // map.setView(current_loc, 13);
      //     console.log('layer.options', layer.options)
      //   }
      // });

       // console.log('window.map', window.map)

      window.vendor_map.eachLayer(function(layer){
        if (layer._markers) {
          // layer.eachMarker(function(aa){
          //   alert(aa);
          // })
          // console.log('layer_markers', layer._markers);
          $.each(layer._markers, function( index, value, d ) {
            // console.log('index', index);
            // console.log('d', d);
              // console.log('marker', value.options.id);

            if (value.options.id == id) {
              // window.vendor_map.setView(location, 16);
              // value.openPopup();
            }
          })

        } else {
          // console.log('(Nav cluster', layer.options.id);
        }
        // console.log('layer.options.id', layer.options.id);
        // console.log('.getAllChildMarkers().length', layer.getAllChildMarkers().length)
        if (layer.options.id == id) {
          // layer.openPopup();
        }


        window.vendor_map.setView(location, 13);
          setTimeout(function(){
            window.vendor_map.eachLayer(function(layer){
              if (layer.options.id === id) {
                  layer.openPopup();
              }
            })
          }, 100);

        // window.vendor_map.once("moveend", mapSetviewCallbacks(id))
        // window.vendor_map.once('moveend', function() {
        //   setTimeout(function(){
        //     window.vendor_map.eachLayer(function(layer){
        //       if (layer.options.id === id) {
        //           layer.fire('click');


        //       }
        //     })
        //   }, 1);
        // });


      });



      // $.each(map._layers, function (layer) {
      //     console.log('layer.options', layer)
      //   // if (layer.options.id === id) {
      //     // map.setView(current_loc, 13);
      //   // }
      // });


    }, function() {
      // console.log('out');
    }
  );

  if ($('#vendor_index_map').length) {
    start_loading('#vendor_index_map');
    let ids = $('#vendor_index_map').data("ids");

    $.ajax({
        url: "/vendors/fetch.json",
        type: 'POST',
        data: { ids: ids },
        // dataType: 'script',
        format: 'json',
        cache: false,
        success:function (data, status){
          stop_loading('#vendor_index_map');
          if (data.coordinates === "[]") {

          } else {
            initGeoSearch(data.current_loc, data.coordinates);
            // console.log('MAP', window.vendor_map);
          }
        }
    });



    // var addressPoints = [
    //   [-37.8210922667, 175.2209316333, "2"],
    //   [-37.8210819833, 175.2213903167, "3"],
    //   [-37.8210881833, 175.2215004833, "3A"],
    //   [-37.8211946833, 175.2213655333, "1"],
    //   [-37.8194342167, 175.22322975, "9"]
    // ];


    //     for (var i = 0; i < addressPoints.length; i++) {
    //       var a = addressPoints[i];
    //       var title = a[2];
    //       var marker = L.marker(new L.LatLng(a[0], a[1]), { title: title });
    //       marker.bindPopup(title);
    //       markers.addLayer(marker);
    //     }

    //     map.addLayer(markers);

    // L.marker([56.946285, 24.105078], {
    //           draggable: true,
    //           title: 'Hello',
    //           bubblingMouseEvents: true
    //         }).addTo(map).bindPopup("This is the Transamerica Pyramid").openPopup();;

    // marker.bindPopup("This is the Transamerica Pyramid").openPopup();

  }
}


function initGeoSearch(current_loc, coordinates) {
    var container = L.DomUtil.get('vendor_index_map');

    var map = L.map('vendor_index_map', {
      gestureHandling: L.Browser.mobile,
      gestureHandlingOptions: {
        text: {
            touch: I18n.t('js.map.touch'),
            scroll: I18n.t('js.map.scroll'),
            scrollMac: I18n.t('js.map.scrollMac')
        }
      }
    }).setView(current_loc, 13);

    L.tileLayer('https://{s}.tile.osm.org/{z}/{x}/{y}.png', {
      maxZoom: 18,
      minZoom: 2,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    }).addTo(map);

    map.on('click', function(ev) {
        console.log('koordinatas', ev.latlng); // ev is an event object (MouseEvent in this case)
    });


    let customOptions =
        {
        'maxWidth': '500',
        'minWidth': '200',
        'className' : 'custom'
        }
    let markers = L.markerClusterGroup({showCoverageOnHover: false});

    // markers.on('spiderfied', function (a) {
    //     // a.layer is actually a cluster
    //     console.log('cluster ' + a.layer.getAllChildMarkers().length);
    // });

    const newLayerGroup = L.layerGroup().addTo(map);

    $.each(JSON.parse(coordinates), function( index, value, d ) {
      // console.log('value', value);
      // alert( index + ": " + value );
      let icon = L.icon({
        xxx: value['id'],
        iconUrl: "https://digi.wedding/pub_imgs/digi_pin.png",
        iconSize: [28, 41], // size of the icon
        popupAnchor: [5,-30],
        iconAnchor: [10, 35]
      });


      // let LeafIcon = L.Icon.extend({
      //   options: {
      //     iconUrl: "https://digi.wedding/pub_imgs/digi_pin.png",
      //     iconSize: [28, 41], // size of the icon
      //     popupAnchor: [5,-30],
      //     iconAnchor: [10, 35],
      //     custom_id: value['id'],
      //     className: 'myPin'
      //   }
      // });


      // console.log('LeafIcon', LeafIcon);



      let infos = "<div class='vendor-map-popup'><a href='"+ value['link_url'] +"' target='_blank'><div class='pin_popup text-center semi-bold' id="+value['id']+">"+ value['title'] +"</div></a></div>"
      let marker = L.marker([value['x'], value['y']], {id: 'YOBELIS', icon: icon, bubblingMouseEvents: true, vendor_id: value['id'], alt: value.title, title: value.title, id: value['id']}).bindPopup(infos, customOptions);
            // marker = L.marker([value['x'], value['y']], {icon: icon, bubblingMouseEvents: true, offer_id: value['id']}).bindPopup('', customOptions).setPopupContent(infos);
      // marker.on('click', value['link_url']);
      // console.log('marker', marker);
      // marker.addTo(map);
      markers.addLayer(marker);
    });





      newLayerGroup.addLayer(markers, {xxx: 'xxx'});

    // map.addLayer(markers);
    map.fitBounds(markers.getBounds(markers), {padding: [50, 50]});


    window.vendor_map = map
    // map.on('popupopen', function(centerMarker) {
    //   zoom = map.getZoom();
    //   let cM = map.project(centerMarker.popup._latlng);
    //   map.setView(map.unproject(cM), zoom, {animate: true});
    // });
}

function initVendorProfileMap() {
  if ($("#vendo-profile-map").length) {

    var L = window.L;
    let coordinates = $('#vendo-profile-map').data("coordinates");

    var icon = L.icon({
      iconUrl: "https://digi.wedding/pub_imgs/digi_pin.png",
      iconSize: [28, 41], // size of the icon
      popupAnchor: [13,-10],
      iconAnchor: [10, 35]
    })

    let map = L.map('vendo-profile-map').setView(coordinates, 15);

    L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(map);

    L.marker(coordinates, { icon: icon }).addTo(map);
  }
}

function initKlix() {
  if ($("#klixPaymentWrapper").length) {
    let PublicRestApi = require('Klix').default;
    PublicRestApi.ApiClient.instance.basePath = "https://portal.klix.app/api/v1/";
    PublicRestApi.ApiClient.instance.token = "p2RyImD6H7xwwSMaoIV79UZaixUdzKH47N4IaKagb8RLNcYYBdjQjufUFmGCw2s-2DPGnZiF4AtjMGw2c4_ohg=="
    let apiInstance = new PublicRestApi.PaymentApi();
    let brandId = "c3829c8e-41e0-43ef-a1e2-dfe06413e67f";
    $('.api_data-basePath').html(PublicRestApi.ApiClient.instance.basePath);
    $('.api_data-token').html(PublicRestApi.ApiClient.instance.token);
    $('.api_data-brandId').html(brandId);
    let callback = (error, data, response) => {
      if (error) {
        console.log('API call failed. Error:');
        console.error(error);
        $('.api_data-response').html(error);
      } else {
        console.log('API called successfully. Returned data:');
        console.log(data);
        $('.api_data-response').html(data);
      }
    }

    apiInstance.paymentMethods(brandId, "EUR", {}, callback);

    console.log('PublicRestApi', PublicRestApi.ApiClient.instance.basePath)
  }
}


function initVendorSwiper() {
  if ($(".mySwiper").length) {
    var swiper = new Swiper(".mySwiper", {
      spaceBetween: 0,
      simulateTouch: true,
      loop: true,
      autoplay: {
        delay: 2500,
        disableOnIteration: true
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },

       breakpoints: {
          // when window width is >= 320px
          320: {
            slidesPerView: 1
          },
          // when window width is >= 480px
          480: {
            slidesPerView: 2
          },
          // when window width is >= 640px
          640: {
            slidesPerView: 3
          },
          800: {
            slidesPerView: 4
          }
        }

    });
  }


  if ($(".myFavoritesVendor").length) {
    var swiper = new Swiper(".myFavoritesVendor", {
      slidesPerView: 1,
      spaceBetween: 20,
      effect: "fade",
      simulateTouch: true,
      loop: true,
      autoplay: {
        delay: 2500
      },
      loopFillGroupWithBlank: false,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },

       breakpoints: {
          // when window width is >= 320px
          320: {
            slidesPerView: 1
          },
          // when window width is >= 480px
          480: {
            slidesPerView: 2
          },
          // when window width is >= 640px
          640: {
            slidesPerView: 3
          },
          800: {
            slidesPerView: 4
          }
        }

    });
  }
}

// Reload slideshow if new img uploaded
function reloadSlideshow() {
  let hash = $(".slideshow").data('slideshow_hash');
  let count = $(".slideshow").data('count');

  $.ajax({
    url: '/failiem_folders/'+ hash +'/slideshow.json',
    method: 'GET',
    data: { count: count },
    cache: false,
    success: function(data) {
      console.log('data', data)
      if(data.count == 'changed' ){
        reloadPage();
      } else {

      }
    }
  });
}

function initSlideshow() {
  if ($(".slideshow").length) {
    // setTimeout(reloadSlideshow(), 8000);
    setInterval(function() {reloadSlideshow()}, 20000);
    // reloadSlideshow();

    let $swiperWrapper = $('.swiper-wrapper');
    let slidesPerView = $swiperWrapper.data('slides') || 1;
    let speed = $swiperWrapper.data('speed') || 5000;
    let swiper = new Swiper(".slideshow", {
      spaceBetween: 0,
      loop: true,
      parallax: true,
      speed: speed,
      slidesPerView: slidesPerView,
      autoplay: {
        delay: speed,
        disableOnInteraction: false,
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      }
    });
  }
}


function reloadPage() {
  location.reload(true);
}

function digi_notifiction(id, e) {
  // console.log([id, e]);
  let duration = 5000;
  let html = e;
  // console.log('el', $('#'+ id +'.popup-notification').length);
  if ($('#'+ id +'.popup-notification').length == 0){
    var $newdiv1 = $( "<div id='" + id + "' class='popup-notification'></div>" )
    $newdiv1.append(html)
    $('#digi_notifiction').append($newdiv1).show(500).css("opacity", "1");
  } else {

  }

  // if ($( "#digi_notifiction").includes(html)) {
  //   console.log('Satur')
  // } else {
  //   console.log('NESATUR')
  // }



  // if ($('#digi_notifiction').find)


  setTimeout(function () {
    $newdiv1.hide(500).detach();
  }, duration);

}


$(document).on("click", ".my-vendor_category-trigger", function(){
  let $this = $(this);
  let id = $this.data('id');

  $('.vendor_category-item').removeClass('active');
  $this.parents('.vendor_category-item').addClass('active');
  console.log('id', id)
  $.ajax({
    url: '/user_vendors/set_current_vendor_category.js',
    method: 'GET',
    data: { vendor_category_id: id },
    cache: false
  });
});

// function preventDoubleClick() {
//   $(".save-button").one('click', function (event) {
//     let $this = $(this);
//     event.preventDefault();
//     $this.prop('disabled', true);

//     // $this.button('loading');
//     //   setTimeout(function() {
//     //      $this.button('reset');
//     //  }, 8000);


//   });
// }

$(document).on("click", ".save-button", function(){
  // let $this = $(this);
  // let form_id = $this.prop('form');

  // let $form = $("#" + form_id);
  // $form.empty();

  // if($form[0].checkValidity()) {
  //   // $this.prop('disabled', true);
  //   alert(1);
  // } else {
  //   alert(2);
  //   $form[0].reportValidity();
  // }
// let form_id = 'modal_form';
// $('form[id="'+form_id+'"]')
});



$(document).on("click", ".enable-edit .card-item", function(){
  let $this = $(this);
  let id = $this.data('id');

  if($(".card-item-toolbox:visible").data('id') != id) {
    $.ajax({
      url: '/card_items/'+ id +'/toolbox.js',
      method: 'GET',
      data: {  },
      cache: false
    });
  }

  // let $searchInput = $this.find('textarea');
  // if ($searchInput.is(":focus")){
  //   console.log('ĪR fok');
  // } else {
  //   console.log('NAV fok');
  //   var strLength = $searchInput.val().length * 2;
  //   $searchInput.focus();
  //   $searchInput[0].setSelectionRange(strLength, strLength);
  //   console.log('id', id)
  // }
});

$(document).on("click", "#card-inner", function(e){
  // console.log('e.target', e.target.className);
  if (e.target.className != 'card-item_textarea'){
    $('#card-item-toolbox').empty();
  }
});

$(document).on('input change', '#card_item_font_size', function() {
    let $this = $(this);
    let val = $this.val();
    let card_item_id = $('.card-item-toolbox').data('id');
    let $card_item = $('.card-item[data-id='+ card_item_id +']')
    let textarea_el = $('.card-item[data-id='+ card_item_id +'] textarea');
    let textarea = document.getElementById(card_item_id)
    let curr_left = parseInt($card_item.css('left').replace('px',''))
    let curr_width = $card_item.width();
    textarea_el.focus();


    // let real_font_size = textarea_el.css('font-size').replace('px','')

    // if (val > real_font_size) {
    //   // console.log("sizse", [val, real_font_size])
    //   let diff = parseInt(val) - parseInt(real_font_size)
    //   console.log("diff", diff)

    //   let curr_left = parseInt($('.card-item[data-id='+ card_item_id +']').css('left').replace('px',''))
    //   let target_left = curr_left - diff
    //   console.log("target_left", target_left)
    //   $('.card-item[data-id='+ card_item_id +']').css('left', target_left + "px")
    // }


    // console.log('offsetHeight', textarea.offsetHeight);

    // textarea.trigger('input');


// offset = $this.offset();


    let lineHeight = Math.floor(parseInt(val.replace('px','')) * 1.5);
    $card_item.css({'font-size': val+'px', 'line-height': lineHeight+'px'});
    $card_item.find('textarea').css({'height': "auto"});
    $card_item.find('textarea').css({'width': "auto"});




    // if (textarea.offsetHeight < textarea.scrollHeight) {
      $card_item.find('textarea').css({'height': textarea.scrollHeight  + "px"});
      $card_item.css({'height': textarea.scrollHeight  + "px"});
    // }

    let scrolw = textarea.scrollWidth;
    let diff = scrolw - curr_width
    let new_left = curr_left - (diff / 2)
    $card_item.css({'left': new_left + 'px'});

    // let scrolw = textarea.scrollWidth;
    // console.log('scrolw', scrolw)
    // let half = scrolw / 2




    // let curr_left = parseInt($('.card-item[data-id='+ card_item_id +']').css('left').replace('px',''))
    // let target_left = curr_left - half
    // console.log("target_left", target_left)
    // $('.card-item[data-id='+ card_item_id +']').css('left', target_left + "px")


    $card_item.find('textarea').css({'width': scrolw  + "px"});
    $card_item.css({'width': scrolw  + "px"});

    $('#card_item_height').val(textarea.scrollHeight);
    $('#card_item_width').val(scrolw);
    $('#card_item_posx').val(new_left);
    // $this.find('textarea').prop('scrollWidth')
    $('#toolbox-submit').addClass('btn-primary').removeClass('btn-outline-primary');

    saveCarItem(card_item_id);
});

// $(document).on('input change', '#card_item_color', function() {
//     let $this = $(this);
//     let val = $this.val();
//     let card_item_id = $('.card-item-toolbox').data('id');
//     // console.log('card_item_id', val);
//     $('.card-item[data-id='+ card_item_id +']').css({'color': val});
//     $('#toolbox-submit').addClass('btn-primary').removeClass('btn-outline-primary');
// });

$(document).on('change', '#color-picker-wrapper input', function() {
    let $this = $(this);
    let val = $("#color-picker-wrapper input[name='card_item[color]']:checked").val();
    // console.log('card_item_id', val);
    // let val = $this.val();
    let card_item_id = $('.card-item-toolbox').data('id');
    $('.card-item[data-id='+ card_item_id +']').css({'color': val});
    $('#toolbox-submit').addClass('btn-primary').removeClass('btn-outline-primary');

    saveCarItem(card_item_id);
});




$(document).on("change keyup paste", ".card-item textarea", function(){
  let $this = $(this).parent();
  let id = $this.data('id');
  // let left = $this.css( "left" ).replace('px','') - $this.wid;
  // console.log('left', left);
  // $('.card-item[data-id='+ id +']').css({'left': left+'px'});
  let textarea = $this.find('textarea');
  let card_item = {}
  // $this.css({'height': "auto"});
  // $this.css({'width': "auto"});
  // $('.card-item[data-id='+ card_item_id +']').css({'width': textarea.scrollWidth  + "px"});
   console.log('offsetWidth', $this.find('textarea').prop('offsetWidth'));
   console.log('Width', textarea.width());

  $this.width(textarea.width());

  saveCarItem(id);
  // card_item['body'] = $this.find('textarea').val();
  // card_item['height'] = $this.find('textarea').prop('scrollHeight');
  // card_item['width'] = $this.find('textarea').prop('scrollWidth');

  // // console.log('card_item', card_item);
  // $.ajax({
  //   url: '/card_items/' + id + '.json',
  //   method: 'PATCH',
  //   data: { card_item },
  //   success: function(data, status) {

  //   },
  //   cache: false
  // });
})


$(document).on("click", "#font_family_select li", function(){
  let val = $(this).data('value');
  let card_item_id = $('.card-item-toolbox').data('id');
  $('.card-item[data-id='+ card_item_id +']').css({'font-family': val});
  let shortText = jQuery.trim(val).substring(0, 15).trim(this) + "...";
  $('#font_family_label').html(shortText);
  $('#card_item_font_family').val(val);
  $('#toolbox-submit').addClass('btn-primary').removeClass('btn-outline-primary');
  saveCarItem(card_item_id);
})


function saveBlobToDb(dom_id, id, url) {
  start_loading('#card-wrapper');
  const node = document.getElementById(dom_id);
  htmlToImage.toPng(node)
    .then(function (dataUrl) {
      // download(dataUrl, 'ssss.png');
      // console.log('dataUrl', dataUrl)
      $.ajax({
        url: '/user_cards/' + id + '/update_image.json',
        method: 'POST',
        data: { data_url: dataUrl },
        success: function(data, status) {
          stop_loading('#card-wrapper');
          $('#card-save-button').addClass('d-none');
          window.location.href = url
        },
        cache: false
      });
    });
}

$(document).on("click", ".save-card-image", function(){
  let $this = $(this);
  let id = $this.data('dom_id');
  let url = $this.data('href');
  let user_card_id = $this.data('user_card_id');
  saveBlobToDb(id, user_card_id, url);
})



$(document).on("click", "#print_as_image, .print_as_image", function(){
  let id = $(this).data('dom_id');
  let name = $(this).data('file_name');
  const node = document.getElementById(id);

  let user_card_id = $('#card-wrapper').data('user_card_id')

  if (user_card_id) {
    saveBlobToDb(id, user_card_id, '#save-image')
  }

  // const fontEmbedCss = htmlToImage.getFontEmbedCSS(node);
  // console.log('fontEmbedCss', fontEmbedCss)

  // htmlToImage.toPng(node, { fontEmbedCss });


    // htmlToImage.toSvg(node, { fontEmbedCss });




// htmlToImage.toPixelData(node)
//   .then(function (pixels) {
//     for (var y = 0; y < node.scrollHeight; ++y) {
//       for (var x = 0; x < node.scrollWidth; ++x) {
//         pixelAtXYOffset = (4 * y * node.scrollHeight) + (4 * x);
//         /* pixelAtXY is a Uint8Array[4] containing RGBA values of the pixel at (x, y) in the range 0..255 */
//         pixelAtXY = pixels.slice(pixelAtXYOffset, pixelAtXYOffset + 4);
//       }
//     }
//   });

// var node = document.getElementById('my-node');


// htmlToImage.toPng(node)
//   .then(function (dataUrl) {
//     download(dataUrl, 'ssss.png');
//   });

// domtoimage.toPng(node)
//     .then(function (dataUrl) {
//         var img = new Image();
//         img.src = dataUrl;
//         document.body.appendChild(img);
//     })
//     .catch(function (error) {
//         console.error('oops, something went wrong!', error);
//     });
  // htmlToImage.toBlob(node)
  // .then(function (blob) {
  //   console.log('blob', blob)
  //   window.saveAs(blob, 'my-node.png');

  // });

  saveAsPng(node,
    { filename: name, printDate: false },
    { backgroundColor: 'rgb(255,255,255)', }
  );
})

$(document).on("click", ".croswords-svg", function(){
  let id = $(this).data('dom_id');
  let name = $(this).data('file_name');
  const node = document.getElementById(id);
 
    htmlToImage.toSvg(node, { quality: 0.50 })
    .then(function (dataUrl) {
        const link = document.createElement('a');
        link.download = 'croswords.svg';
        link.href = dataUrl;
        link.click();
    })
    .catch(function (error) {
        console.error('Error converting to SVG:', error);
    });
})

$(document).on("click", ".croswords-pdf", function() {
  let id = $(this).data('dom_id');
  let name = $(this).data('file_name');
  const node = document.getElementById(id);

  // Set options without scaling the SVG
  const options = {
      quality: 1, // Maximum quality
      width: node.offsetWidth,
      height: node.offsetHeight,
  };

  // Convert the node to SVG
  htmlToImage.toSvg(node, options)
      .then(function (svgDataUrl) {
          // Convert the SVG data URL into a blob
          const svgBlob = new Blob([svgDataUrl], { type: 'image/svg+xml;charset=utf-8' });

          // Create a file reader to read the blob as text
          const reader = new FileReader();
          reader.onloadend = function() {
              // Once we have the SVG content as text, we can add it to the PDF
              let svgText = reader.result;

              // Create a jsPDF instance
              const pdf = new jsPDF({
                  orientation: 'landscape',
                  unit: 'pt',
                  format: [node.offsetWidth, node.offsetHeight]
              });

              // Add the SVG content directly to the PDF using SVGtoPDF
              SVGtoPDF(pdf, svgText, 0, 0, {
                  width: node.offsetWidth,
                  height: node.offsetHeight,
                  preserveAspectRatio: 'xMinYMin meet',
              });

              // Save the PDF
              pdf.save(name + '.pdf');
          };

          // Read the blob as text
          reader.readAsText(svgBlob);
      })
      .catch(function (error) {
          console.error('Error converting to PDF:', error);
      });
});

$(document).on("change", "#croswords-download-options", function(){
  let val = $(this).val();
  $('.croswords-download-btn').data('dom_id', val);
})

$(document).on("click", ".croswords-png", function() {
  start_loading('#download-block');
  let id = $(this).data('dom_id');
  let name = $(this).data('file_name');
  const node = document.getElementById(id);

  // Define the scale for increasing image resolution
  const scale = 8; // Change this to suit your needs

  // Set options with increased width and height
  const options = {
      quality: 1, // Maximum quality
      width: node.offsetWidth * scale,
      height: node.offsetHeight * scale,
      style: {
          transform: 'scale('+scale+')',
          transformOrigin: 'top left',
          width: node.offsetWidth + 'px',
          height: node.offsetHeight + 'px'
      }
  };
  
  // Convert the node to SVG and then to PNG
  htmlToImage.toSvg(node, options)
      .then(function (svgDataUrl) {
          // Create an image to render the SVG
          const img = new Image();
          img.src = svgDataUrl;

          img.onload = function() {
              // Once the SVG is loaded, draw it to a canvas at the increased size
              const canvas = document.createElement('canvas');
              const ctx = canvas.getContext('2d');
              canvas.width = node.offsetWidth * scale;
              canvas.height = node.offsetHeight * scale;

              ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

              // Export the canvas to PNG
              canvas.toBlob(function(blob) {
                  // Create a link to download the PNG blob
                  const pngUrl = URL.createObjectURL(blob);
                  const link = document.createElement('a');
                  link.download = name + '.png';
                  link.href = pngUrl;
                  link.click();
                  
                  // Clean up the object URL
                  URL.revokeObjectURL(pngUrl);
              }, 'image/png');
              stop_loading('#download-block');
          };
      })
      .catch(function (error) {
          console.error('Error converting to PNG:', error);
      });
      
});





function saveCarItem(id) {
  const rgb2hex = (rgb) => `#${rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/).slice(1).map(n => parseInt(n, 10).toString(16).padStart(2, '0')).join('')}`

  let $this = $('.card-item[data-id='+ id +']')
  let finalOffset = $this.offset();
  let finalxPos = $this.position().left;
  let finalyPos = $this.position().top;
  let card_item = {}

  card_item['posx'] = finalxPos;
  card_item['posy'] = finalyPos;
  card_item['body'] = $this.find('textarea').val();
  card_item['height'] = $this.find('textarea').prop('scrollHeight');
  card_item['width'] = $this.find('textarea').prop('scrollWidth');
  card_item['color'] = rgb2hex($this.find('textarea').css('color'));
  card_item['font_family'] = $this.find('textarea').css('font-family');
  card_item['font_size'] = parseInt($this.find('textarea').css('font-size').replace('px',''));

  // save image to backend
  let user_card_id = $('#card-wrapper').data('user_card_id')

  $.ajax({
    url: '/card_items/' + id + '.json',
    method: 'PATCH',
    data: { card_item },
    success: function(data, status) {
      // console.log('data', data['card_id'])


    },
    cache: false
  });
}



function initCards() {
  if ($("#card-inner").length) {
    fitToParent(document.getElementById('card-inner'), 0);
    // let cardSaveInterval = window.setInterval(function(){
    //   let dom_id = 'card-inner'
    //   let id = $('#card-wrapper').data('user_card_id');
    //   saveBlobToDb(dom_id, id, '#save-image');
    // }, 60000);

    $(window).bind('beforeunload', function(){
      let dom_id = 'card-inner'
      let id = $('#card-wrapper').data('user_card_id');
      saveBlobToDb(dom_id, id, '#save-image');
    });

  }

  $(".enable-edit .card-item :input").on('mousedown', function (e) {
    // console.log('e', e);
      var mdown = document.createEvent("MouseEvents");
      mdown.initMouseEvent("mousedown", false, true, window, 0, e.screenX, e.screenY, e.clientX, e.clientY, true, false, false, true, 0, null);
      $(this).closest('.card-item')[0].dispatchEvent(mdown);
  }).on('click', function (e) {
      var $draggable = $(this).closest('.card-item');
      if ($draggable.data("preventBehaviour")) {
          e.preventDefault();
          $draggable.data("preventBehaviour", false)
      }
  });

    let opts = {

    };


  $( ".enable-edit .card-item" ).draggable({
    cancel: '',
    start: function (event, ui) {
        $(this).data('preventBehaviour', true);
    },
    drag: function() {
      let $this = $(this);
      let offset = $this.offset();
      // top_pos = (offset.top - ($card_item.height() / 2) + $('#card-item-toolbox').height());
      let top_pos = (offset.top - 2);
      let left_pos = (offset.left + $this.width() + 20);
      if (left_pos > $("#card-inner").offset().left + 450) {
        left_pos = $("#card-inner").offset().left + 460
      }

      if (top_pos > 830) {
        top_pos = 830
      }

      $('#card-item-toolbox').css({'top': top_pos+'px', 'left': left_pos+'px'});

    },
    stop: function() {
      let $this = $(this);
      let id = $this.data('id');
      saveCarItem(id);
    }
  }, opts);
}


// guest invitations



function toggleInvitationBulkActions() {
  $("#invitation_guest_list-wrapper .form-simple-check-input")
  let numberOfChecked = $('#invitation_guest_list-wrapper .form-simple-check-input:checked:not(#toggle-all-checks)').length;
  if (numberOfChecked > 0) {
    $("#guest-index-bulk-actions-wrapper").show().addClass('d-inline-flex');
    $(".multi-select-container").hide();
    $("#guest-search").hide();
    $("#selected-guest-count").html(numberOfChecked);
  } else {
    $("#guest-index-bulk-actions-wrapper").hide().removeClass('d-inline-flex');
    $(".multi-select-container").show();
    $("#guest-search").show();
  }
}

// $(document).on("click", ".remote-form-check-input", function(){
//   let id = $(this).data('id');
//   let is_definitely_invited = $(this).attr('value');
//   console.log('is_definitely_invited', is_definitely_invited);
//   let guest_group = {}
//   guest_group['is_definitely_invited'] = is_definitely_invited;

//   $.ajax({
//     url: '/guests/' + id + '.js',
//     method: 'PATCH',
//     data: { guest_group },
//     success: function(data, status) {
//       // toastr.info('Saglabāts');
//     },
//     cache: false
//   });
// });





function transformScaleBlocks() {
  if ($("#card-inner:not(.enable-edit)").length) {
    fitToParent(document.getElementById('card-inner'), 0);
  }
}


function scaleAmountNeededToFit(el, margin = 0) {
  const parentSize = {
    width: el.parentElement.clientWidth - margin * 2,
    height: el.parentElement.clientHeight - margin * 2
  };

  return Math.min(parentSize.width / el.clientWidth,
    parentSize.height / el.clientHeight);
}

function fitToParent(element, margin) {
  const scale = scaleAmountNeededToFit(element, margin);
  element.style.transformOrigin = "0 0";
  element.style.transform = `translate(${margin}px, ${margin}px) scale(${scale})`;
}



function initVisionBoard() {
  initVisionBoardDragablle();
  initVisionBoardDroppable();
  initVisionBoardResizable();
  // alert("")
}


if ($("#vision_board.enable-edit").length) {

// initVisionBoard
}


function initVisionBoardResizable() {

// $('#vision_board').resizeable().bind({
//     resizestop : function(event,ui) {alert('1')},
// });

  $( "#vision_board_wrapper" ).resizable({
    animate: false,
    minWidth: 1000,
    minHeight: 700,
    stop: function( event, ui ) {
      console.log('size', [ui]);
      let id = $('#vision_board_wrapper').data('id');

      let vision_board = {}
        vision_board['width'] = ui.size.width;
        vision_board['height'] = ui.size.height;

      $.ajax({
        url: '/vision_boards/' + id + '.json',
        method: 'PATCH',
        data: { vision_board },
        success: function(data, status) {

        },
        cache: false
      });

    }
  });

  $( ".vision-board-item-inner.type-img" ).resizable({
    animate: false,
    minWidth: 200,
    minHeight: 200,
    handles: 'e, w',
    stop: function( event, ui ) {
      console.log('size', [ui]);
      console.log('size', ui.element[0].dataset.id);
      let id = ui.element[0].dataset.id;

      let vision_board_item = {}
        vision_board_item['width'] = ui.size.width;
        vision_board_item['height'] = ui.size.height;

      $.ajax({
        url: '/vision_board_items/' + id + '.json',
        method: 'PATCH',
        data: { vision_board_item },
        success: function(data, status) {

        },
        cache: false
      });

    }
  });
}


function initVisionBoardDragablle() {
  $( ".enable-edit .vision-board-item" ).draggable({
    containment: "#vision_board_wrapper",
    start: function() {

    },
    drag: function() {

    },
    stop: function() {
      let $this = $(this);
      let id = $this.data('id');
      let vision_board_item = {}
      var finalOffset = $this.offset();
      var finalxPos = $this.position().left;
      var finalyPos = $this.position().top;
      vision_board_item['posx'] = finalxPos;
      vision_board_item['posy'] = finalyPos;

      // $this.find('.table-coords').html(finalxPos + finalyPos);

      $.ajax({
        url: '/vision_board_items/' + id + '.json',
        method: 'PATCH',
        data: { vision_board_item },
        success: function(data, status) {

        },
        cache: false
      });

      initVisionBoard();

    }
  });
}

function initVisionBoardDroppable() {
  $('.new-vision-board-item').draggable({
      helper : "clone"
     });
   $('#vision_board').droppable({
       accept      : ".new-vision-board-item",
       drop        : function(event,ui)
      {
        let $this = $(this);
        let type = ui.helper[0].dataset.type;
        // let position = $this.position;
        let posy = (ui.position.top - $this.offset().top);
        let posx = (ui.position.left - $this.offset().left);
        console.log('0', ui.helper[0].dataset.type);
        console.log('first', ui.helper.first);
        console.log('posy', posy);
        console.log('posx', posx);
        // console.log('position', position);
         var dropedItem = $(ui.draggable).clone();
         // dropedItem.addClass('draggable-item').removeClass('new-table-item').css({'top': posy, 'left': posx});
         // console.log(dropedItem.html());
         // $this.append(dropedItem);

        let target = '/vision_board_items/new?posx='+ posx +'&posy='+ posy +'&type='+ type +'';

        call_modal(target, "layout=popup");

        // $.ajax({
        //   url: '/tables.js',
        //   method: 'POST',
        //   data: { table },
        //   success: function(data, status) {

        //   },
        //   cache: false
        // });

         initVisionBoard();
      }
  });
}

$(document).on('click', "[data-delete_key]", function(ev) {
  let $this = $(this);
  let hash = $this.data("hash"), delete_key = $this.data("delete_key");
  console.log('data', [hash, delete_key])
  $.ajax({
    url: '/albums/delete_img.js',
    method: 'GET',
    data: { hash: hash, delete_key: delete_key }
  });
});

// CHATBOT

$(document).on('click', "#chat-submit", function(e) {
  e.preventDefault();
  var prompt = $('#chat-prompt').val();
  if (prompt) {
    $.ajax({
      url: '/chats/chat',
      type: 'POST',
      dataType: 'json',
      data: { prompt: 'User: ' + prompt },
      success: function(data) {
        var response = data.response;
        $('.chat-messages').append('<div class="chat-message"><span class="user">Tu: </span><span class="user-message">' + prompt + '</span></div>');
        $('.chat-messages').append('<div class="chat-message"><span class="bot">Digi Wedding: </span><span class="bot-message">' + response + '</span></div>');
        $('#chat-prompt').val('');
      }
    });
  }
});


$(document).on('click', "#generate-button", function(e) {
  var idea = $('#idea').val();
  if (idea) {
    $.ajax({
      url: '/chats/generate_image',
      type: 'POST',
      dataType: 'json',
      data: { idea: idea },
      success: function(data) {
        var image_urls = data.image_urls;
        console.log('image_urls', image_urls);
        for (var i = 0; i < image_urls.length; i++) {
          $('#generated-image-' + (i + 1)).attr('src', image_urls[i]);
        }
      }
    });
  }
});

$(document).on('click', ".font_family_select li", function(e) {
  let target_id = $(this).parent().data('target');
  let button = $(this).parent().siblings('.font_family_select-dropdown');
  console.log('target_id', target_id);
  let value = $(this).data('value');
  console.log('value', value);
  let $target = $('[id='+ target_id +']');
  $target.val(value)
  button.html(value)
});

$(document).on('click', ".postition-ckeck", function(e) {
  $('.postition-ckeck').removeClass('active');
  let $this = $(this);
  let pos = $this.data('position');
  $('#crossword_puzzle_position').val(pos);
  $this.addClass('active');
});

